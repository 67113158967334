<template>
  <div>
    <b-table-simple
      caption-top
      responsive
      style="padding-right: 28px; padding-left: 28px; overflow-y: hidden; border: 0px"
    >
      <b-thead>
        <b-tr style="border: 0px">
          <b-th class="table-background-color" colspan="3"> SERVICES </b-th>
          <b-th class="table-background-color text-left" colspan="3">
            {{ $t("quote.pricePerSqft", "en") }}
          </b-th>
          <b-th class="table-background-color text-left" colspan="3"> COGS/SQFT </b-th>
          <b-th class="table-background-color text-left" colspan="3">
            {{ $t("quote.subtotal", "en") }}
          </b-th>
          <b-th class="table-background-color text-left" colspan="3"> PROFIT/SQFT </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr class="border-b">
          <b-td class="row-label" colspan="3">
            <div class="text_summary"><b>Construction</b></div>
          </b-td>
          <b-td class="row-label text-left" colspan="3" style="vertical-align: top"
            >{{
              Number(
                (Number(bCtrSubtotal) + Number(ctrSubtotal) + Number(allowSubtotal)) /
                  quote.sqft
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td colspan="3" class="cost-line text-left">{{
            Number(
              (Number(bCtrCostSubtotal) +
                Number(ctrCostSubtotal) +
                Number(allowCostSubtotal)) /
                quote.sqft
            ).toLocaleString("en-CA", {
              minimumFractionDigits: 2,
              style: "currency",
              currency: "CAD",
            })
          }}</b-td>
          <b-td class="text-left row-label" colspan="3" style="vertical-align: top"
            >{{
              Number(
                Number(bCtrSubtotal) + Number(ctrSubtotal) + Number(allowSubtotal)
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td colspan="3"></b-td>
        </b-tr>
        <b-tr class="border-b">
          <b-td colspan="3">
            <div class="ml-2 text_summary">Base Contract</div>
          </b-td>
          <b-td
            class="child-line row-label child_numbers"
            colspan="3"
            style="vertical-align: top"
            >{{
              Number(bCtrSubtotal / quote.sqft).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td colspan="3" class="cost-line text-left child_numbers">
            {{
              Number(bCtrCostSubtotal / quote.sqft).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td
            class="text-muted text-left child_numbers"
            colspan="3"
            style="vertical-align: top"
            >{{
              Number(bCtrSubtotal).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td colspan="3" class="cost-line text-left child_numbers">
            {{
              Number(
                Number(bCtrSubtotal / quote.sqft) - Number(bCtrCostSubtotal / quote.sqft)
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
        </b-tr>
        <b-tr
          style="background-color: #f8f8f8"
          class="hidden-flex-line"
          v-if="checkHiddenConstruction"
        >
          <b-td class="child-line row-label" colspan="3">
            <div class="ml-4">Additions Rolled Up in Base Contract</div>
          </b-td>
          <b-td class="row-label child-line text-left" colspan="3"
            >{{
              Number(
                quote.site_conditions_construction_price_per_sqft +
                  quote.options_construction_price_per_sqft
              )
                .toFixed(2)
                .toLocaleString("en-CA", {
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "CAD",
                })
            }}
          </b-td>

          <b-td class="row-label child-line text-left" colspan="3"
            >{{
              Number(
                quote.options_construction_cost_per_sqft +
                  quote.site_conditions_construction_cost_per_sqft
              )
                .toFixed(2)
                .toLocaleString("en-CA", {
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "CAD",
                })
            }}
          </b-td>

          <b-td class="row-label child-line text-left" colspan="3"
            >{{
              Number(
                siteConditionsConstructionSubtotal + optionsConstructionSubtotal
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td class="row-label child-line text-left" colspan="3">
            <!-- {{
              "$" +
              (
                Number(
                  quote.site_conditions_construction_price_per_sqft +
                    quote.options_construction_price_per_sqft
                ) -
                Number(
                  quote.options_construction_cost_per_sqft +
                    quote.site_conditions_construction_cost_per_sqft
                )
              )
                .toFixed(2)
                .toLocaleString("en", { minimumFractionDigits: 2 })
            }} -->
          </b-td>
        </b-tr>
        <b-tr class="border-b" v-if="ctrItems.length > 0">
          <b-td colspan="3">
            <div class="ml-2 text_summary">Base Contract Additions</div>
          </b-td>
          <b-td
            class="text-muted text-left child_numbers"
            colspan="3"
            style="vertical-align: top"
            >{{
              Number(ctrSubtotal / quote.sqft).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>

          <b-td colspan="3" class="cost-line text-left child_numbers">
            {{
              Number(ctrCostSubtotal / quote.sqft).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td
            class="text-muted text-left child_numbers"
            colspan="3"
            style="vertical-align: top"
            >{{
              Number(ctrSubtotal).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>

          <b-td colspan="3" class="cost-line text-left child_numbers">
            {{
              Number(
                Number(ctrSubtotal / quote.sqft) - Number(ctrCostSubtotal / quote.sqft)
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
        </b-tr>
        <b-tr class="border-b" v-if="allowItems.length > 0">
          <b-td colspan="3">
            <div class="ml-2 text_summary">Cash Allowances</div>
          </b-td>
          <b-td
            class="text-muted text-left child_numbers"
            colspan="3"
            style="vertical-align: top"
            >{{
              Number(allowSubtotal / quote.sqft).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>

          <b-td colspan="3" class="cost-line text-left child_numbers">{{
            Number(allowCostSubtotal / quote.sqft).toLocaleString("en-CA", {
              minimumFractionDigits: 2,
              style: "currency",
              currency: "CAD",
            })
          }}</b-td>
          <b-td
            class="text-muted text-left child_numbers"
            colspan="3"
            style="vertical-align: top"
            >{{
              Number(allowSubtotal).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td colspan="3" class="cost-line text-left child_numbers">
            {{
              Number(
                Number(allowSubtotal / quote.sqft) -
                  Number(allowCostSubtotal / quote.sqft)
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
        </b-tr>
      </b-tbody>
      <b-tbody
        v-show="
          quote.ffe_toggle ||
          quote.options_ffe_price_per_sqft > 0 ||
          ffeItems.length > 0 ||
          allowFfeItems.length > 0 ||
          isBaseContractAddition
        "
      >
        <b-tr class="border-b">
          <b-td class="row-label" colspan="3">
            <div class="text_summary"><b>Furniture</b></div>
          </b-td>
          <b-td class="row-label text-left" colspan="3" style="vertical-align: top">
            {{
              Number(
                (quote.ffe_toggle ? ffe_calc / quote.sqft : 0) +
                  (ffeSubtotal + Number(allowFfeSubtotal) + ffeBcSubtotal) / quote.sqft
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>

          <b-td colspan="3" class="cost-line text-left">
            {{
              Number(
                (quote.ffe_toggle ? Number(ffe_cost_per_sqft) : 0) +
                  (ffeCostSubtotal + Number(allowFfeCostSubtotal) + ffeBcCostSubtotal) /
                    quote.sqft
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td class="text-left row-label" colspan="3" style="vertical-align: top"
            >{{
              Number(
                (quote.ffe_toggle ? ffe_calc : 0) +
                  ffeSubtotal +
                  Number(allowFfeSubtotal) +
                  ffeBcSubtotal
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td colspan="3"></b-td>
        </b-tr>
        <b-tr
          class="border-b"
          v-if="
            (quote.ffe_spec_quote.length > 0 && quote.ffe_toggle) ||
            isBaseContractAddition
          "
        >
          <b-td colspan="3">
            <div class="ml-2 text_summary">Base Contract</div>
          </b-td>
          <b-td
            class="text-muted text-left child_numbers"
            colspan="3"
            style="vertical-align: top"
            >{{
              Number(
                (quote.ffe_toggle ? ffe_calc / quote.sqft : 0) +
                  ffeBcSubtotal / quote.sqft
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>

          <b-td colspan="3" class="cost-line text-left child_numbers">
            {{
              Number(
                (quote.ffe_toggle ? ffe_cost_per_sqft : 0) +
                  ffeBcCostSubtotal / quote.sqft
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td
            class="text-muted text-left child_numbers"
            colspan="3"
            style="vertical-align: top"
            >{{
              Number((quote.ffe_toggle ? ffe_calc : 0) + ffeBcSubtotal).toLocaleString(
                "en-CA",
                {
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "CAD",
                }
              )
            }}
          </b-td>

          <b-td colspan="3" class="cost-line text-left child_numbers">
            {{
              Number(
                (quote.ffe_toggle
                  ? Number(ffe_calc - ffeDeliveryInstallCalc) / quote.sqft
                  : 0) +
                  ffeBcSubtotal / quote.sqft -
                  Number(
                    (quote.ffe_toggle ? ffe_cost_per_sqft : 0) +
                      ffeBcCostSubtotal / quote.sqft
                  )
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
        </b-tr>
        <b-tr
          style="background-color: #f8f8f8"
          class="hidden-flex-line"
          v-if="checkHiddenFFE"
        >
          <b-td class="child-line row-label" colspan="3">
            <div class="ml-4">Additions Rolled Up in Base Contract</div>
          </b-td>
          <b-td class="row-label child-line text-left child_numbers" colspan="3"
            >{{
              Number(quote.options_ffe_price_per_sqft)
                .toFixed(2)
                .toLocaleString("en-CA", {
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "CAD",
                })
            }}
          </b-td>
          <b-td class="row-label child-line text-left child_numbers" colspan="3"
            >{{
              Number(quote.options_ffe_cost_per_sqft).toFixed(2).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td class="row-label child-line text-left child_numbers" colspan="3"
            >{{
              Number(optionsFfeSubtotal).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td class="row-label child-line text-left child_numbers" colspan="3">
            <!-- ${{
              (
                Number(quote.options_ffe_price_per_sqft) -
                Number(quote.options_ffe_cost_per_sqft)
              )
                .toFixed(2)
                .toLocaleString("en", { minimumFractionDigits: 2 })
            }} -->
          </b-td>
        </b-tr>
        <b-tr class="border-b" v-if="ffeItems.length > 0">
          <b-td colspan="3">
            <div class="ml-2 text_summary">Base Contract Additions</div>
          </b-td>
          <b-td
            class="text-muted text-left child_numbers"
            colspan="3"
            style="vertical-align: top"
            >{{
              Number(ffeSubtotal / quote.sqft).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td colspan="3" class="cost-line text-left child_numbers">{{
            Number(ffeCostSubtotal / quote.sqft).toLocaleString("en-CA", {
              minimumFractionDigits: 2,
              style: "currency",
              currency: "CAD",
            })
          }}</b-td>
          <b-td
            class="text-muted text-left child_numbers"
            colspan="3"
            style="vertical-align: top"
            >{{
              Number(ffeSubtotal).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td colspan="3" class="cost-line text-left child_numbers">{{
            Number(
              Number(ffeSubtotal / quote.sqft) - Number(ffeCostSubtotal / quote.sqft)
            ).toLocaleString("en-CA", {
              minimumFractionDigits: 2,
              style: "currency",
              currency: "CAD",
            })
          }}</b-td>
        </b-tr>
        <b-tr class="border-b" v-if="allowFfeItems.length > 0">
          <b-td colspan="3">
            <div class="ml-2 text_summary">Cash Allowances</div>
          </b-td>
          <b-td
            class="text-muted text-left child_numbers"
            colspan="3"
            style="vertical-align: top"
            >{{
              Number(allowFfeSubtotal / quote.sqft).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td colspan="3" class="cost-line text-left child_numbers">{{
            Number(allowFfeCostSubtotal / quote.sqft).toLocaleString("en-CA", {
              minimumFractionDigits: 2,
              style: "currency",
              currency: "CAD",
            })
          }}</b-td>
          <b-td
            class="text-muted text-left child_numbers"
            colspan="3"
            style="vertical-align: top"
            >{{
              Number(allowFfeSubtotal).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td colspan="3" class="cost-line text-left child_numbers">{{
            Number(
              Number(allowFfeSubtotal / quote.sqft) -
                Number(allowFfeCostSubtotal / quote.sqft)
            ).toLocaleString("en-CA", {
              minimumFractionDigits: 2,
              style: "currency",
              currency: "CAD",
            })
          }}</b-td>
        </b-tr>
      </b-tbody>
      <b-tbody
        v-show="
          quote.av_toggle ||
          quote.it_toggle ||
          avitItems.length > 0 ||
          allowAvItems.length > 0
        "
      >
        <b-tr class="border-b">
          <b-td class="row-label" colspan="3">
            <div class="text_summary"><b>Audio Visual & Networking</b></div>
          </b-td>
          <b-td class="row-label text-left" colspan="3" style="vertical-align: top"
            >{{
              Number(
                (avitGlobalSubTotal() + Number(avitSubtotal) + Number(allowAvSubtotal)) /
                  quote.sqft
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td colspan="3" class="cost-line text-left">{{
            Number(
              (avitGlobalCostSubTotal() +
                Number(avitCostSubtotal) +
                Number(allowAvCostSubtotal)) /
                quote.sqft
            ).toLocaleString("en-CA", {
              minimumFractionDigits: 2,
              style: "currency",
              currency: "CAD",
            })
          }}</b-td>
          <b-td class="text-left row-label" colspan="3" style="vertical-align: top"
            >{{
              Number(
                avitGlobalSubTotal() + Number(avitSubtotal) + Number(allowAvSubtotal)
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td colspan="3"></b-td>
        </b-tr>
        <b-tr class="border-b" v-if="quote.av_toggle || quote.it_toggle">
          <b-td colspan="3">
            <div class="ml-2 text_summary">Base Contract</div>
          </b-td>

          <b-td
            class="text-muted text-left child_numbers"
            colspan="3"
            style="vertical-align: top"
            >{{
              Number(avitGlobalSubTotal() / quote.sqft).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>

          <b-td colspan="3" class="cost-line text-left child_numbers">{{
            Number(avitGlobalCostSubTotal() / quote.sqft).toLocaleString("en-CA", {
              minimumFractionDigits: 2,
              style: "currency",
              currency: "CAD",
            })
          }}</b-td>
          <b-td
            class="text-muted text-left child_numbers"
            colspan="3"
            style="vertical-align: top"
            >{{
              Number(avitGlobalSubTotal()).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td colspan="3" class="cost-line text-left child_numbers">
            {{
              Number(
                (Number(avitGlobalSubTotal() - avitDeliveryInstallCalc) -
                  Number(avitGlobalCostSubTotal())) /
                  quote.sqft
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
        </b-tr>

        <b-tr class="border-b" v-if="avitItems.length > 0">
          <b-td colspan="3">
            <div class="ml-2 text_summary">Base Contract Additions</div>
          </b-td>
          <b-td
            class="text-muted text-left child_numbers"
            colspan="3"
            style="vertical-align: top"
            >{{
              Number(avitSubtotal / quote.sqft).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td colspan="3" class="cost-line text-left child_numbers">{{
            Number(avitCostSubtotal / quote.sqft).toLocaleString("en-CA", {
              minimumFractionDigits: 2,
              style: "currency",
              currency: "CAD",
            })
          }}</b-td>
          <b-td
            class="text-muted text-left child_numbers"
            colspan="3"
            style="vertical-align: top"
            >{{
              Number(avitSubtotal).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td colspan="3" class="cost-line text-left child_numbers">
            {{
              Number(
                Number(avitSubtotal / quote.sqft) - Number(avitCostSubtotal / quote.sqft)
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
        </b-tr>
        <b-tr class="border-b" v-if="allowAvItems.length > 0">
          <b-td colspan="3">
            <div class="ml-2 text_summary">Cash Allowances</div>
          </b-td>
          <b-td
            class="text-muted text-left child_numbers"
            colspan="3"
            style="vertical-align: top"
            >{{
              Number(allowAvSubtotal / quote.sqft).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td colspan="3" class="cost-line text-left child_numbers">{{
            Number(allowAvCostSubtotal / quote.sqft).toLocaleString("en-CA", {
              minimumFractionDigits: 2,
              style: "currency",
              currency: "CAD",
            })
          }}</b-td>
          <b-td
            class="text-muted text-left child_numbers"
            colspan="3"
            style="vertical-align: top"
            >{{
              Number(allowAvSubtotal).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td colspan="3" class="cost-line text-left child_numbers">
            {{
              Number(
                Number(allowAvSubtotal / quote.sqft) -
                  Number(allowAvCostSubtotal / quote.sqft)
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
        </b-tr>
      </b-tbody>
      <b-tbody>
        <b-tr class="border-b">
          <b-td class="row-label" colspan="3">
            <div class="text_summary"><b>Soft Costs</b></div>
          </b-td>
          <b-td class="row-label text-left" colspan="3" style="vertical-align: top"
            >{{
              Number(
                quote.construction_pm_buffer * 1 +
                  quote.design_ame_buffer * 1 +
                  Number(quote.engineering_fee_price) +
                  Number(Number(quote.permit_fees) / Number(quote.sqft)) +
                  (Number(allowScSubtotal) + Number(scSubtotal)) / quote.sqft
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td colspan="3"></b-td>
          <b-td class="text-left row-label" colspan="3" style="vertical-align: top"
            >{{
              Number(
                (quote.construction_pm_buffer * 1 +
                  quote.design_ame_buffer * 1 +
                  Number(quote.engineering_fee_price)) *
                  quote.sqft +
                  Number(quote.permit_fees) +
                  Number(allowScSubtotal) +
                  Number(scSubtotal)
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td colspan="3"></b-td>
        </b-tr>
        <b-tr class="border-b">
          <b-td class="row-label" colspan="3">
            <div class="ml-2 text_summary">Base Contract</div>
          </b-td>
          <b-td
            class="text-muted text-left child_numbers"
            colspan="3"
            style="vertical-align: top"
            >{{
              Number(
                quote.construction_pm_buffer * 1 +
                  quote.design_ame_buffer * 1 +
                  Number(quote.engineering_fee_price) +
                  +Number(Number(quote.permit_fees) / Number(quote.sqft))
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td colspan="3" class="cost-line text-left child_numbers">
            {{
              Number(
                quote.construction_pm * 1 +
                  0 * 1 +
                  Number(quote.engineering_fee_cost) +
                  +Number(quote.permit_fees_cost_per_sqft)
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td
            class="text-muted text-left child_numbers"
            colspan="3"
            style="vertical-align: top"
            >{{
              Number(
                (quote.construction_pm_buffer * 1 +
                  quote.design_ame_buffer * 1 +
                  Number(quote.engineering_fee_price)) *
                  quote.sqft +
                  Number(quote.permit_fees)
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td colspan="3"></b-td>
        </b-tr>

        <b-tr>
          <b-td class="child-label" colspan="3">
            <div class="ml-4">Design + Architectural</div>
          </b-td>
          <b-td class="cost-line text-left child_numbers" colspan="3"
            >{{
              "$" +
              Number(quote.design_ame_buffer * 1).toLocaleString("en", {
                minimumFractionDigits: 2,
              })
            }}
          </b-td>
          <b-td class="cost-line text-left child_numbers" colspan="3">$0.00 </b-td>
          <b-td class="cost-line text-left child_numbers" colspan="3"></b-td>
          <b-td class="text-left child_numbers" colspan="3">
            <b-form-input
              :disabled="
                quote.tfr.tfr_status == 'Quote Completed' ||
                quote.opportunity.ccdc_signed == true
              "
              class="text-left"
              v-model="quote.design_ame_buffer"
              type="number"
            />
          </b-td>
        </b-tr>
        <b-tr>
          <b-td class="child-label" colspan="3">
            <div class="ml-4">Project Management</div>
          </b-td>
          <b-td class="cost-line text-left child_numbers" colspan="3"
            >{{
              Number(quote.construction_pm_buffer * 1).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td class="cost-line text-left child_numbers" colspan="3">
            {{
              Number(quote.construction_pm * 1).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td class="cost-line text-left child_numbers" colspan="3" />
          <b-td colspan="3" class="text-left">
            <b-form-input
              :disabled="
                quote.tfr.tfr_status == 'Quote Completed' ||
                quote.opportunity.ccdc_signed == true
              "
              class="text-left"
              v-model="quote.construction_pm_buffer"
              type="number"
            />
          </b-td>
        </b-tr>
        <b-tr>
          <b-td class="child-label" colspan="3"
            ><div class="ml-4">Engineering Fee</div></b-td
          >
          <b-td class="row-label child-line child_numbers" colspan="3">
            <b-form-input
              class="text-left"
              :disabled="
                quote.tfr.tfr_status == 'Quote Completed' ||
                quote.opportunity.ccdc_signed == true
              "
              v-model="quote.engineering_fee_price"
              type="number"
            />
          </b-td>
          <b-td class="cost-line text-left child_numbers" colspan="3"
            >{{
              "$" +
              Number(Number(quote.engineering_fee_cost) * 1).toLocaleString("en", {
                minimumFractionDigits: 2,
              })
            }}
          </b-td>
          <b-td class="cost-line" colspan="3" />
          <b-td class="cost-line text-left child_numbers" colspan="3">
            {{
              Number(
                Number(Number(quote.engineering_fee_price)) * 1 -
                  Number(quote.engineering_fee_cost) * 1
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-td colspan="3"> <div class="ml-4">Permits</div> </b-td>
          <b-td class="text-left cost-line child_numbers" colspan="3">
            {{
              Number(quote.permit_fees / quote.sqft).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td class="text-left cost-line child_numbers" colspan="3"
            >{{
              Number(quote.permit_fees_cost_per_sqft).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td colspan="3">
            <b-form-input
              :disabled="
                quote.tfr.tfr_status == 'Quote Completed' ||
                quote.opportunity.ccdc_signed == true
              "
              class="text-left"
              v-model="quote.permit_fees"
              type="number"
            />
          </b-td>
          <b-td colspan="3" class="cost-line text-left child_numbers">
            {{
              Number(
                Number(quote.permit_fees / quote.sqft) -
                  Number(quote.permit_fees_cost_per_sqft)
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
        </b-tr>
        <b-tr class="border-b" v-if="scItems.length > 0">
          <b-td class="row-label" colspan="3">
            <div class="ml-2 text_summary">Base Contract Additions</div>
          </b-td>
          <b-td class="text-muted text-left" colspan="3" style="vertical-align: top"
            >{{
              Number(scSubtotal / quote.sqft).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td colspan="3" class="text-muted text-left child_numbers">{{
            Number(scCostSubtotal / quote.sqft).toLocaleString("en-CA", {
              minimumFractionDigits: 2,
              style: "currency",
              currency: "CAD",
            })
          }}</b-td>
          <b-td
            class="text-muted text-left child_numbers"
            colspan="3"
            style="vertical-align: top"
            >{{
              Number(scSubtotal).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td colspan="3" class="cost-line text-left child_numbers">
            {{
              Number(
                Number(scSubtotal / quote.sqft) - Number(scCostSubtotal / quote.sqft)
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
        </b-tr>
        <b-tr class="border-b" v-if="allowScItems.length > 0">
          <b-td class="row-label" colspan="3">
            <div class="ml-2 text_summary">Cash Allowances</div>
          </b-td>
          <b-td
            class="text-muted text-left child_numbers"
            colspan="3"
            style="vertical-align: top"
            >{{
              Number(allowScSubtotal / quote.sqft).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>

          <b-td colspan="3" class="cost-line text-left child_numbers">
            {{
              Number(allowScCostSubtotal / quote.sqft).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td
            class="text-muted text-left child_numbers"
            colspan="3"
            style="vertical-align: top"
            >{{
              Number(allowScSubtotal).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>

          <b-td colspan="3" class="cost-line text-left child_numbers">
            {{
              Number(
                Number(allowScSubtotal / quote.sqft) -
                  Number(allowScCostSubtotal / quote.sqft)
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
        </b-tr>
      </b-tbody>
      <b-tfoot>
        <!--  v-if="
                          Number(Math.abs(quote.site_conditions_price_per_sqft)) <= 0 &&
                          Number(Math.abs(quote.options_price_per_sqft)) <= 0
                        " -->
        <b-tr class="border-footer">
          <b-td
            class="row-label project-total-label"
            colspan="3"
            style="border-top: none"
          >
            Estimated Project Total
          </b-td>
          <b-td
            style="color: #6e6b7b"
            class="row-label project-total-number text-left"
            colspan="3"
            >{{
              Number(estimateContractTotal / quote.sqft).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td colspan="3" class="cost-line text-left">
            {{
              Number(estimateCostContractTotalSQFT).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td
            colspan="3"
            style="color: #6e6b7b"
            class="row-label project-total-number text-left"
            >{{
              Number(estimateContractTotal).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td colspan="3" class="cost-line text-left">
            {{
              Number(
                Number(
                  (estimateContractTotal -
                    ffeDeliveryInstallCalc -
                    avitDeliveryInstallCalc) /
                    quote.sqft
                ) - estimateCostContractTotalSQFT
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
        </b-tr>
      </b-tfoot>
    </b-table-simple>
    <br /><br />
    <!-- <b-table-simple
      caption-top
      responsive
      style="padding-right: 28px; padding-left: 28px"
    >
      <b-thead>
        <b-tr>
          <b-th class="table-background-color" colspan="3" style="text-align: left">
            SERVICES
          </b-th>
          <b-th class="table-background-color" colspan="3" style="text-align: left">
            PRICE/SQFT
          </b-th>
          <b-th
            class="table-background-color cost-header"
            colspan="3"
            style="text-align: left"
          >
            COGS/SQFT
          </b-th>
          <b-th class="table-background-color" colspan="3" style="text-align: left">
            SUBTOTAL
          </b-th>
          <b-th
            class="table-background-color cost-header"
            colspan="3"
            style="text-align: left"
          >
            PROFIT/SQFT
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-td class="row-label" colspan="3"> Hard Construction Costs </b-td>
          <b-td class="row-label" colspan="3"
            >${{
              (
                quote.construction_cost_per_sqft +
                quote.site_conditions_construction_price_per_sqft +
                quote.options_construction_price_per_sqft +
                quote.construction_buffer * 1
              ).toLocaleString("en", { minimumFractionDigits: 2 })
            }}
          </b-td>
          <b-td class="row-label cost-line" colspan="3"
            >{{
              "$" +
              Number(
                quote.construction_cost_per_sqft +
                  quote.options_construction_cost_per_sqft +
                  quote.site_conditions_construction_cost_per_sqft
              ).toLocaleString("en", { minimumFractionDigits: 2 })
            }}
          </b-td>
          <b-td colspan="3"
            >{{
              "$" +
              Number(
                quote.construction_buffer * 1 * quote.sqft +
                  siteConditionsConstructionSubtotal +
                  optionsConstructionSubtotal +
                  quote.construction_calc
              ).toLocaleString("en", { minimumFractionDigits: 2 })
            }}
          </b-td>
          <b-td colspan="3">
            {{
              "$" +
              Number(quote.construction_buffer).toLocaleString("en", {
                minimumFractionDigits: 2,
              })
            }}
          </b-td>
        </b-tr>
        <b-tr class="remove-border hidden-flex-line" v-if="checkHiddenConstruction">
          <b-td class="child-line row-label" colspan="3">
            Additions Rolled Up in Base Contract
          </b-td>
          <b-td class="row-label child-line" colspan="3"
            >{{
              "$" +
              Number(
                quote.site_conditions_construction_price_per_sqft +
                  quote.options_construction_price_per_sqft
              )
                .toFixed(2)
                .toLocaleString("en", { minimumFractionDigits: 2 })
            }}
          </b-td>
          <b-td class="row-label child-line" colspan="3"
            >{{
              "$" +
              Number(
                quote.options_construction_cost_per_sqft +
                  quote.site_conditions_construction_cost_per_sqft
              )
                .toFixed(2)
                .toLocaleString("en", { minimumFractionDigits: 2 })
            }}
          </b-td>
          <b-td class="row-label child-line" colspan="3"
            >{{
              "$" +
              Number(
                siteConditionsConstructionSubtotal + optionsConstructionSubtotal
              ).toLocaleString("en", { minimumFractionDigits: 2 })
            }}
          </b-td>
          <b-td class="row-label child-line" colspan="3"
            >{{
              "$" +
              (
                Number(
                  quote.site_conditions_construction_price_per_sqft +
                    quote.options_construction_price_per_sqft
                ) -
                Number(
                  quote.options_construction_cost_per_sqft +
                    quote.site_conditions_construction_cost_per_sqft
                )
              )
                .toFixed(2)
                .toLocaleString("en", { minimumFractionDigits: 2 })
            }}
          </b-td>
        </b-tr>
        <b-tr :class="!checkHiddenFFE ? ' border-section ' : ''">
          <b-td
            :class="
              !quote.ffe_toggle && !checkHiddenFFE
                ? 'row-label border-child cost-line'
                : 'row-label border-child'
            "
            colspan="3"
            >Furniture & Fixtures
          </b-td>
          <b-td
            :class="
              !quote.ffe_toggle && !checkHiddenFFE
                ? 'row-label border-child cost-line'
                : 'row-label border-child'
            "
            colspan="3"
            >${{
              Number(
                (!quote.ffe_toggle ? 0 : quote.ffe_price_per_sqft) +
                  quote.options_ffe_price_per_sqft
              ).toLocaleString("en", { minimumFractionDigits: 2 })
            }}
          </b-td>
          <b-td class="row-label border-child cost-line" colspan="3"
            >{{
              "$" +
              Number(
                (!quote.ffe_toggle
                  ? 0
                  : ffe_cost_per_sqft + quote.ffe_installation_price_per_sqft) +
                  quote.options_ffe_cost_per_sqft
              ).toLocaleString("en", { minimumFractionDigits: 2 })
            }}
          </b-td>
          <b-td
            :class="
              !quote.ffe_toggle && !checkHiddenFFE
                ? 'border-child cost-line'
                : 'border-child'
            "
            colspan="3"
            >{{
              "$" +
              Number(
                (!quote.ffe_toggle ? 0 : ffe_calc) + optionsFfeSubtotal
              ).toLocaleString("en", { minimumFractionDigits: 2 })
            }}
          </b-td>
          <b-td
            :class="
              (!quote.ffe_toggle && !checkHiddenFFE
                ? 'border-child cost-line'
                : 'border-child') + ' cost-line'
            "
            colspan="3"
            >{{
              "$" +
              Number(
                (!quote.ffe_toggle ? 0 : quote.ffe_buffer) +
                  Number(quote.options_ffe_price_per_sqft) -
                  Number(quote.options_ffe_cost_per_sqft)
              ).toLocaleString("en", { minimumFractionDigits: 2 })
            }}
          </b-td>
        </b-tr>
        <b-tr class="hidden-flex-line" v-if="checkHiddenFFE">
          <b-td class="child-line row-label" colspan="3">
            Additions Rolled Up in Base Contract
          </b-td>
          <b-td class="row-label child-line" colspan="3"
            >{{
              "$" +
              Number(quote.options_ffe_price_per_sqft)
                .toFixed(2)
                .toLocaleString("en", { minimumFractionDigits: 2 })
            }}
          </b-td>
          <b-td class="row-label child-line" colspan="3"
            >{{
              "$" +
              Number(quote.options_ffe_cost_per_sqft)
                .toFixed(2)
                .toLocaleString("en", { minimumFractionDigits: 2 })
            }}
          </b-td>
          <b-td class="row-label child-line" colspan="3"
            >{{
              "$" +
              Number(optionsFfeSubtotal).toLocaleString("en", {
                minimumFractionDigits: 2,
              })
            }}
          </b-td>
          <b-td class="row-label child-line" colspan="3"
            >${{
              (
                Number(quote.options_ffe_price_per_sqft) -
                Number(quote.options_ffe_cost_per_sqft)
              )
                .toFixed(2)
                .toLocaleString("en", { minimumFractionDigits: 2 })
            }}
          </b-td>
        </b-tr>
        <b-tr :class="!checkHiddenAVIT ? ' border-section ' : ''">
          <b-td
            :class="
              !quote.avit_toggle && !checkHiddenAVIT
                ? 'row-label border-child cost-line'
                : 'row-label border-child'
            "
            colspan="3"
            >AV / IT
          </b-td>
          <b-td
            :class="
              !quote.avit_toggle && !checkHiddenAVIT
                ? 'row-label border-child cost-line'
                : 'row-label border-child'
            "
            colspan="3"
          >
            {{
              "$" +
              Number(
                (!quote.avit_toggle ? 0 : quote.avit_price_per_sqft) +
                  quote.options_avit_price_per_sqft
              ).toLocaleString("en", { minimumFractionDigits: 2 })
            }}
          </b-td>
          <b-td class="row-label border-child cost-line" colspan="3"
            >{{
              "$" +
              Number(
                (!quote.avit_toggle
                  ? 0
                  : quote.avit_cost_per_sqft + quote.avit_installation_price_per_sqft) +
                  quote.options_avit_cost_per_sqft
              ).toLocaleString("en", { minimumFractionDigits: 2 })
            }}
          </b-td>
          <b-td
            :class="
              !quote.avit_toggle && !checkHiddenAVIT
                ? 'border-child cost-line'
                : 'border-child'
            "
            colspan="3"
            >{{
              "$" +
              Number(
                (!quote.avit_toggle ? 0 : quote.avit_calc) + optionsAvItSubtotal
              ).toLocaleString("en", { minimumFractionDigits: 2 })
            }}
          </b-td>
          <b-td
            :class="
              (!quote.avit_toggle && !checkHiddenAVIT
                ? 'border-child cost-line'
                : 'border-child') + ' cost-line'
            "
            colspan="3"
            >{{
              "$" +
              Number(
                (!quote.avit_toggle ? 0 : quote.avit_buffer) +
                  (quote.options_avit_price_per_sqft - quote.options_avit_cost_per_sqft)
              ).toLocaleString("en", { minimumFractionDigits: 2 })
            }}
          </b-td>
        </b-tr>
        <b-tr class="hidden-flex-line" v-if="checkHiddenAVIT">
          <b-td class="child-line row-label" colspan="3">
            Additional Scope & Upgrades included in Turnkey
          </b-td>
          <b-td class="row-label child-line" colspan="3"
            >{{
              "$" +
              Number(quote.options_avit_price_per_sqft)
                .toFixed(2)
                .toLocaleString("en", { minimumFractionDigits: 2 })
            }}
          </b-td>
          <b-td class="row-label child-line" colspan="3"
            >{{
              "$" +
              Number(quote.options_avit_cost_per_sqft)
                .toFixed(2)
                .toLocaleString("en", { minimumFractionDigits: 2 })
            }}
          </b-td>
          <b-td class="row-label child-line" colspan="3"
            >{{
              "$" +
              Number(optionsAvItSubtotal).toLocaleString("en", {
                minimumFractionDigits: 2,
              })
            }}
          </b-td>
          <b-td class="row-label child-line" colspan="3"
            >{{
              "$" +
              Number(quote.options_avit_price_per_sqft - quote.options_avit_cost_per_sqft)
                .toFixed(2)
                .toLocaleString("en", { minimumFractionDigits: 2 })
            }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-td class="row-label border-child" colspan="3"> Soft Cost </b-td>
          <b-td class="row-label border-child" colspan="3"
            >{{
              Number(
                quote.construction_pm_buffer * 1 +
                  quote.design_ame_buffer * 1 +
                  Number(quote.engineering_fee_price) * 1 +
                  +Number(Number(quote.permit_fees) / Number(quote.sqft))
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td class="row-label border-child cost-line" colspan="3"> </b-td>
          <b-td class="border-child" colspan="3"
            >{{
              "$" +
              Number(
                (quote.construction_pm_buffer * 1 +
                  quote.design_ame_buffer * 1 +
                  Number(quote.engineering_fee_price) * 1) *
                  quote.sqft
              ).toLocaleString("en", { minimumFractionDigits: 2 })
            }}
          </b-td>
          <b-td class="border-child" colspan="3" />
        </b-tr>
        <b-tr>
          <b-td class="child-label border-child" colspan="3">
            Design + Architectural
          </b-td>
          <b-td class="row-label border-child child-line" colspan="3"
            >{{
              "$" +
              Number(quote.design_ame_buffer * 1).toLocaleString("en", {
                minimumFractionDigits: 2,
              })
            }}
          </b-td>
          <b-td class="row-label border-child cost-line" colspan="3"> </b-td>
          <b-td class="border-child" colspan="3" />
          <b-td class="border-child" colspan="3">
            <b-form-input v-model="quote.design_ame_buffer" type="number" />
          </b-td>
        </b-tr>
        <b-tr>
          <b-td class="child-label border-child" colspan="3"> Project Management </b-td>
          <b-td class="row-label child-line border-child" colspan="3"
            >{{
              "$" +
              Number(quote.construction_pm_buffer * 1).toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })
            }}
          </b-td>
          <b-td class="row-label border-child cost-line" colspan="3"> </b-td>
          <b-td class="row-label child-line border-child" colspan="3" />
          <b-td colspan="3" class="border-child">
            <b-form-input v-model="quote.construction_pm_buffer" type="number" />
          </b-td>
        </b-tr>
        <b-tr>
          <b-td class="child-label" colspan="3"> Engineering Fee </b-td>
          <b-td class="row-label child-line" colspan="3">
            <b-form-input v-model="quote.engineering_fee_price" type="number" />
          </b-td>
          <b-td class="row-label child-line cost-line" colspan="3"
            >{{
              "$" +
              Number(Number(quote.engineering_fee_cost) * 1).toLocaleString("en", {
                minimumFractionDigits: 2,
              })
            }}
          </b-td>
          <b-td class="row-label child-line" colspan="3" />
          <b-td class="row-label child-line cost-line" colspan="3">
            {{
  
                        [((Number(Number(quote.engineering_fee_price)) * 1)-(Number(quote.engineering_fee_cost) * 1) &lt; 0) ? '-' : ''] +'$' + Math.abs(Number(((Number(Number(quote.engineering_fee_price)) * 1)-(Number(quote.engineering_fee_cost) * 1))))
                          .toLocaleString('en', { minimumFractionDigits: 2 })
            }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-td class="row-label" colspan="3"> Permit Fees </b-td>
          <b-td class="row-label" colspan="3">
            {{
              Number(quote.permit_fees / quote.sqft).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td class="row-label cost-line" colspan="3"
            >{{
              "$" +
              Number(permit_fees).toLocaleString("en", {
                minimumFractionDigits: 2,
              })
            }}
          </b-td>
          <b-td colspan="3">
            <b-form-input v-model="quote.permit_fees" type="number" />
          </b-td>
          <b-td colspan="3" />
        </b-tr>
      </b-tbody>
      <b-tfoot>
        <b-tr class="border-footer">
          <b-td class="row-label" colspan="3"> Estimate Project Total </b-td>
          <b-td class="row-label turnkey-subtotal-primary" colspan="3"
            >{{
              Number(
                quote.construction_cost_per_sqft +
                  quote.site_conditions_construction_price_per_sqft +
                  quote.options_construction_price_per_sqft +
                  quote.construction_buffer * 1 +
                  Number(
                    (!quote.ffe_toggle ? 0 : quote.ffe_price_per_sqft) +
                      quote.options_ffe_price_per_sqft +
                      Number(
                        (!quote.avit_toggle ? 0 : quote.avit_price_per_sqft) +
                          quote.options_avit_price_per_sqft
                      ) +
                      Number(
                        quote.construction_pm_buffer * 1 +
                          quote.design_ame_buffer * 1 +
                          Number(quote.engineering_fee_price) * 1
                      ) +
                      Number(quote.permit_fees / quote.sqft)
                  )
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td class="row-label turnkey-subtotal-secondary" colspan="3"
            >{{
              Number(
                Number(
                  quote.construction_cost_per_sqft +
                    quote.options_construction_cost_per_sqft +
                    quote.site_conditions_construction_cost_per_sqft
                ) +
                  Number(
                    (!quote.ffe_toggle
                      ? 0
                      : ffe_cost_per_sqft + quote.ffe_installation_price_per_sqft) +
                      quote.options_ffe_cost_per_sqft
                  ) +
                  Number(
                    (!quote.avit_toggle
                      ? 0
                      : quote.avit_cost_per_sqft +
                        quote.avit_installation_price_per_sqft) +
                      quote.options_avit_cost_per_sqft
                  ) +
                  Number(Number(quote.engineering_fee_cost) * 1) +
                  Number(quote.permit_fees / quote.sqft)
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td class="row-label turnkey-subtotal-primary" colspan="3"
            >{{
              Number(
                Number(
                  quote.construction_pm_buffer * 1 +
                    quote.design_ame_buffer * 1 +
                    Number(quote.engineering_fee_price) * 1
                ) *
                  quote.sqft +
                  Number(quote.permit_fees) +
                  Number((!quote.ffe_toggle ? 0 : ffe_calc) + optionsFfeSubtotal) +
                  Number(
                    (!quote.avit_toggle ? 0 : quote.avit_calc) + optionsAvItSubtotal
                  ) +
                  Number(
                    quote.construction_buffer * 1 * quote.sqft +
                      siteConditionsConstructionSubtotal +
                      optionsConstructionSubtotal +
                      quote.construction_calc
                  )
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td class="row-label turnkey-subtotal-secondary" colspan="3"
            >{{
              Number(
                +Number(quote.construction_buffer) +
                  Number(
                    quote.site_conditions_construction_price_per_sqft +
                      quote.options_construction_price_per_sqft
                  ) -
                  Number(
                    quote.options_construction_cost_per_sqft +
                      quote.site_conditions_construction_cost_per_sqft
                  ) +
                  Number(!quote.ffe_toggle ? 0 : quote.ffe_buffer) +
                  Number(quote.options_ffe_price_per_sqft) -
                  Number(quote.options_ffe_cost_per_sqft) +
                  Number(!quote.avit_toggle ? 0 : quote.avit_buffer) +
                  Number(quote.options_avit_price_per_sqft) -
                  Number(quote.options_avit_cost_per_sqft) +
                  Number(quote.design_ame_buffer) +
                  Number(quote.construction_pm_buffer) +
                  Number(
                    Number(quote.engineering_fee_price) * 1 -
                      quote.engineering_fee_cost * 1
                  )
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
        </b-tr>
      </b-tfoot>
    </b-table-simple> -->
  </div>
</template>

<script>
import {
  BCardBody,
  BCol,
  BRow,
  BTableSimple,
  BTbody,
  BTd,
  BTfoot,
  BTh,
  BThead,
  BTr,
  BFormInput,
} from "bootstrap-vue";
import Logo from "@core/layouts/components/Logo.vue";
export default {
  props: {
    quote: {
      type: Object,
      default: [],
    },
    siteConditionsConstructionSubtotal: {
      type: Number,
      default: 0,
    },

    optionsConstructionSubtotal: {
      type: Number,
      default: 0,
    },
    optionsFfeSubtotal: {
      type: Number,
      default: 0,
    },
    optionsAvItSubtotal: {
      type: Number,
      default: 0,
    },
    checkHiddenConstruction: {
      type: Boolean,
      default: true,
    },

    checkHiddenFFE: {
      type: Boolean,
      default: true,
    },

    checkHiddenAVIT: {
      type: Boolean,
      default: true,
    },

    permit_fees: {
      type: Number,
      default: 0,
    },
  },
  components: {
    BCardBody,
    BCol,
    BRow,
    BTableSimple,
    BTbody,
    BTd,
    BTfoot,
    BTh,
    BThead,
    BTr,
    Logo,
    BFormInput,
  },
  watch: {
    "quote.contingency_fee_percentage": function (newVal, oldVal) {
      return newVal;
    },
    "quote.cm_fee_percentage": function (newVal, oldVal) {
      return newVal;
    },
    "quote.cm_fee_enabled": function (newVal, oldVal) {
      return newVal;
    },
  },
  computed: {
    // hard construction funcs
    ctrSubtotal() {
      let result = this.quote.options
        .filter((c) => c.budget_group == "Construction" && !c.is_base_spec)
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      return result;
    },
    ctrCostSubtotal() {
      let result = this.quote.options
        .filter((c) => c.budget_group == "Construction" && !c.is_base_spec)
        .reduce((total, obj) => Number(obj.total_cost) + total, 0);
      return result;
    },
    bCtrSubtotal() {
      let result = this.quote.construction_spec_quote.reduce(
        (total, obj) =>
          Number(obj.total_price) + parseFloat(this.contingencyFeeCalc(obj)) + total,
        0
      );
      // Quote Totals QA
      // console.log('cnstrn value without contingency fee: '+this.quote.construction_spec_quote.reduce(
      //     (total, obj) =>
      //         Number(obj.total_price) + total,
      //     0
      // )
      // )
      // console.log('contingency with contingency fee: '+result)
      // add cm fee to global price
      result += this.cmFeeCalc * 1;
      result += this.addBcSubtotal;
      return result;
    },
    bCtrCostSubtotal() {
      let result = this.quote.construction_spec_quote.reduce(
        (total, obj) => Number(obj.total_cost) + total,
        0
      );
      result += this.addBcCostSubtotal;
      return result;
    },
    ctrItems() {
      let result = this.quote.options.filter(
        (c) => c.budget_group == "Construction" && !c.is_base_spec
      );
      return result;
    },
    allowItems() {
      let result = this.quote.allowances.filter((c) => c.budget_group == "Construction");
      return result;
    },
    allowSubtotal() {
      let result = this.quote.allowances
        .filter((c) => c.budget_group == "Construction")
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      return result;
    },
    allowCostSubtotal() {
      let result = this.quote.allowances
        .filter((c) => c.budget_group == "Construction")
        .reduce((total, obj) => Number(obj.total_cost) + total, 0);
      return result;
    },
    //FFE funcs
    allowFfeSubtotal() {
      let result = this.quote.allowances
        .filter((c) => c.budget_group == "FF&E")
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      return result;
    },
    allowFfeCostSubtotal() {
      let result = this.quote.allowances
        .filter((c) => c.budget_group == "FF&E")
        .reduce((total, obj) => Number(obj.total_cost) + total, 0);
      return result;
    },
    allowFfeItems() {
      let result = this.quote.allowances.filter((c) => c.budget_group == "FF&E");
      return result;
    },
    ffeSubtotal() {
      let result = this.quote.options
        .filter((c) => c.budget_group == "FF&E" && !c.is_base_spec)
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      return result;
    },
    ffeCostSubtotal() {
      let result = this.quote.options
        .filter((c) => c.budget_group == "FF&E" && !c.is_base_spec)
        .reduce((total, obj) => Number(obj.total_cost) + total, 0);
      return result;
    },
    ffeItems() {
      let result = this.quote.options.filter(
        (c) => c.budget_group == "FF&E" && !c.is_base_spec
      );
      return result;
    },
    // av it funcs
    avitItems() {
      let result = this.quote.options.filter((c) => c.budget_group == "AV/IT");
      return result;
    },
    avitSubtotal() {
      let result = this.quote.options
        .filter((c) => c.budget_group == "AV/IT")
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      return result;
    },
    avitCostSubtotal() {
      let result = this.quote.options
        .filter((c) => c.budget_group == "AV/IT")
        .reduce((total, obj) => Number(obj.total_cost) + total, 0);
      return result;
    },
    allowAvItems() {
      let result = this.quote.allowances.filter((c) => c.budget_group == "AV/IT");
      return result;
    },
    allowAvSubtotal() {
      let result = this.quote.allowances
        .filter((c) => c.budget_group == "AV/IT")
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      return result;
    },
    allowAvCostSubtotal() {
      let result = this.quote.allowances
        .filter((c) => c.budget_group == "AV/IT")
        .reduce((total, obj) => Number(obj.total_cost) + total, 0);
      return result;
    },
    // Soft cost funcs
    scSubtotal() {
      let result = this.quote.options
        .filter((c) => c.budget_group == "Soft Cost")
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      return result;
    },
    scCostSubtotal() {
      let result = this.quote.options
        .filter((c) => c.budget_group == "Soft Cost")
        .reduce((total, obj) => Number(obj.total_cost) + total, 0);
      return result;
    },
    scItems() {
      let result = this.quote.options.filter((c) => c.budget_group == "Soft Cost");
      return result;
    },
    allowScItems() {
      let result = this.quote.allowances.filter((c) => c.budget_group == "Soft Cost");
      return result;
    },
    allowScSubtotal() {
      let result = this.quote.allowances
        .filter((c) => c.budget_group == "Soft Cost")
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      return result;
    },
    allowScCostSubtotal() {
      let result = this.quote.allowances
        .filter((c) => c.budget_group == "Soft Cost")
        .reduce((total, obj) => Number(obj.total_cost) + total, 0);
      return result;
    },
    ffeBcSubtotal() {
      let result = this.quote.options
        .filter((c) => c.budget_group == "FF&E" && c.is_base_spec)
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      return result;
    },
    ffeBcCostSubtotal() {
      let result = this.quote.options
        .filter((c) => c.budget_group == "FF&E" && c.is_base_spec)
        .reduce((total, obj) => Number(obj.total_cost) + total, 0);
      return result;
    },
    isBaseContractAddition() {
      let exist = false;
      let result = this.quote.options.filter(
        (c) => c.budget_group == "FF&E" && c.is_base_spec
      );
      if (result.length > 0) {
        exist = true;
      }
      return exist;
    },
    isAVITBaseContractAddition() {
      let exist = false;
      let result = this.quote.options.filter(
        (c) => c.budget_group == "AV/IT" && c.is_base_spec
      );
      if (result.length > 0) {
        exist = true;
      }
      return exist;
    },
    addBcSubtotal() {
      let result = this.quote.options
        .filter((c) => c.budget_group == "Construction" && c.is_base_spec)
        .reduce(
          (total, obj) =>
            Number(obj.total_price) + parseFloat(this.contingencyFeeCalc(obj)) + total,
          0
        );
      // Quote Totals QA
      // console.log('base spec additions w./o contingency'+this.quote.options
      //     .filter((c) => c.budget_group == "Construction" && c.is_base_spec)
      //     .reduce(
      //         (total, obj) =>
      //             Number(obj.total_price)  + total,
      //         0
      //     ))
      // this.quote.options
      //     .filter((c) => c.budget_group == "Construction" && c.is_base_spec).sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
      //     .map((c)=>{
      //       console.log(c.name)
      //       console.log(Number(c.total_price) + parseFloat(this.contingencyFeeCalc(c)))
      //     })
      // console.log('base spec additions w contingency: '+result)
      return result;
    },
    addBcCostSubtotal() {
      let result = this.quote.options
        .filter((c) => c.budget_group == "Construction" && c.is_base_spec)
        .reduce((total, obj) => Number(obj.total_cost) + total, 0);
      return result;
    },
    estimateContractTotal() {
      let HC =
        Number(this.bCtrSubtotal) + Number(this.ctrSubtotal) + Number(this.allowSubtotal);
      // Quote Totals QA
      // console.log('not base spec additions: '+this.ctrSubtotal)
      // console.log('allowance cstrn subtotal: '+this.allowSubtotal)
      // console.log('Hard construction total: '+HC)
      let FFE =
        (!this.quote.ffe_toggle ? 0 : this.ffe_calc) +
        this.ffeSubtotal +
        Number(this.allowFfeSubtotal) +
        this.ffeBcSubtotal;
      let AVIT =
        this.avitGlobalSubTotal() +
        Number(this.avitSubtotal) +
        Number(this.allowAvSubtotal);
      let SC =
        (this.quote.construction_pm_buffer * 1 +
          this.quote.design_ame_buffer * 1 +
          Number(this.quote.engineering_fee_price)) *
          this.quote.sqft +
        Number(this.quote.permit_fees) +
        Number(this.allowScSubtotal) +
        Number(this.scSubtotal);
      return HC + FFE + AVIT + SC;
    },
    estimateCostContractTotalSQFT() {
      let HC = Number(
        (Number(this.bCtrCostSubtotal) +
          Number(this.ctrCostSubtotal) +
          Number(this.allowCostSubtotal)) /
          this.quote.sqft
      );
      let FFE = Number(
        (this.quote.ffe_toggle ? this.ffe_cost_per_sqft : 0) +
          (this.ffeCostSubtotal +
            Number(this.allowFfeCostSubtotal) +
            Number(this.ffeBcCostSubtotal)) /
            this.quote.sqft
      );
      let AVIT = Number(
        (this.avitGlobalCostSubTotal() +
          Number(this.avitCostSubtotal) +
          Number(this.allowAvCostSubtotal)) /
          this.quote.sqft
      );
      let SC =
        this.quote.construction_pm * 1 +
        0 * 1 +
        Number(this.quote.engineering_fee_cost) +
        +Number(this.quote.permit_fees_cost_per_sqft) +
        (Number(this.allowScCostSubtotal) + Number(this.scCostSubtotal)) /
          this.quote.sqft;
      return HC + FFE + AVIT + SC;
    },
    ffeDeliveryInstallCalc() {
      if(!this.quote.ffe_toggle) return 0;
      return this.quote.ffe_spec_quote.reduce(
        (total, item) =>
          (item.specification_swap == null
            ? item.total_delivery_install
            : Number(item.specification_swap.total_delivery_install)) *
            1 +
          total,
        0
      );
    },
    avitDeliveryInstallCalc() {
      let sum = 0;
      if (this.quote.base_spec_version < 3.3) {
        sum += this.quote.avit_toggle ? this.avitDI("IT") : 0;
        sum += this.quote.avit_toggle ? this.avitDI("AV") : 0;
      } else {
        sum += this.quote.it_toggle ? this.avitDI("IT") : 0;
        sum += this.quote.av_toggle ? this.avitDI("AV") : 0;
      }
      return sum;
    },
    ffe_calc() {
      let sum = 0;
      sum = this.quote.ffe_spec_quote.reduce(
        (total, obj) =>
          (obj.specification_swap == null
            ? Number(obj.total_price)
            : obj.specification_swap.total_price * 1 +
              obj.specification_swap.total_delivery_install * 1) + total,
        0
      );
      return sum;
    },
    ffe_cost_per_sqft() {
      let sum = 0;
      sum = this.quote.ffe_spec_quote.reduce(
        (total, obj) =>
          (obj.specification_swap == null
            ? Number(obj.total_cost)
            : obj.specification_swap.unit_cogs *
              1 *
              obj.specification_swap.quantity *
              1) + total,
        0
      );
      return Number(sum) / Number(this.quote.sqft);
    },
    // change logic to calculate cmfee calc
    // ticket https://www.notion.so/clearspace1/Contingency-CM-Fee-Formulas-302caff0f00b4c0d999e65f43f025d4b
    baseSpecItemsSubTotal() {
      var sum = 0;
      // add validation for cm fee and cstrn margin per item
      this.quote.construction_spec_quote.forEach((e) => {
        sum += parseFloat(e.total_price) + parseFloat(this.contingencyFeeCalc(e));
      });

      this.quote.options
        .filter((n) => n.is_base_spec == true && n.budget_group == "Construction")
        .forEach((e) => {
          sum += parseFloat(e.total_price) + parseFloat(this.contingencyFeeCalc(e));
        });

      this.quote.site_conditions
        .filter((n) => n.is_base_spec == true && n.budget_group == "Construction")
        .forEach((e) => {
          sum += parseFloat(e.total_price);
        });
      return Number(sum).toFixed(2);
    },
    // cm fee calc
    cmFeeCalc() {
      let result = 0;
      if (this.quote.base_spec_version > 3.6) {
        result = this.quote.cm_fee_enabled
          ? Number(this.baseSpecItemsSubTotal) *
            (Number(this.quote.cm_fee_percentage) / 100)
          : 0;
      }
      return result;
    },
  },
  data() {
    return {
      excluded_codes_contingency_fee: [
        "Z1070.30.01",
        "Z1070.30.02",
        "Z1070.30.03",
        "Z1070.40",
        "Z1070.50",
        "Z1070.60",
        "Z1070.80",
        "Z1050.20",
        "Z1050.25",
        "Z1050.30",
        "Z1020.10",
      ],
    };
  },
  mounted() {},
  methods: {
    baseContractSqft() {
      let HC =
        this.quote.construction_cost_per_sqft +
        this.quote.construction_buffer * 1 +
        this.quote.site_conditions_construction_price_per_sqft +
        this.quote.options_construction_price_per_sqft;
      let FFE =
        (!this.quote.ffe_toggle ? 0 : this.quote.ffe_price_per_sqft) +
        this.quote.options_ffe_price_per_sqft;
      let AVIT =
        this.avitGlobalSubTotal() / this.quote.sqft +
        this.quote.options_avit_price_per_sqft;
      let SC =
        this.quote.construction_pm_buffer * 1 +
        this.quote.design_ame_buffer * 1 +
        Number(this.quote.engineering_fee_price) +
        this.quote.permit_fees_cost_per_sqft;
      return HC + FFE + AVIT + SC;
    },
    baseContractTotal() {
      let HC = this.bCtrSubtotal;
      let FFE = (!this.quote.ffe_toggle ? 0 : this.ffe_calc) + this.optionsFfeSubtotal;
      let AVIT = this.avitGlobalSubTotal() + this.optionsAvItSubtotal;
      let SC =
        (this.quote.construction_pm_buffer * 1 +
          this.quote.design_ame_buffer * 1 +
          Number(this.quote.engineering_fee_price)) *
          this.quote.sqft +
        this.quote.permit_fees;
      return HC + FFE + AVIT + SC;
    },
    avitTotals(option) {
      let total = 0;
      total = this.quote.avit_spec_quote
        .filter((x) => x.subcategory == option)
        .reduce(
          (total, obj) =>
            (obj.specification_swap == null
              ? Number(obj.supply_price * obj.qty)
              : obj.specification_swap.total_price * 1) + total,
          0
        );
      return total;
    },
    avitDI(option) {
      let total = 0;
      total = this.quote.avit_spec_quote
        .filter((x) => x.subcategory == option)
        .reduce(
          (total, obj) =>
            (obj.specification_swap == null
              ? Number(obj.unit_delivery_install * obj.qty)
              : Number(obj.specification_swap.total_delivery_install * 1)) + total,
          0
        );
      return total;
    },
    avitCostTotals(option) {
      let total = 0;
      total = this.quote.avit_spec_quote
        .filter((x) => x.subcategory == option)
        .reduce(
          (total, obj) =>
            (obj.specification_swap == null
              ? Number(obj.unit_cost * obj.qty)
              : Number(obj.specification_swap.unit_cogs * 1 * obj.qty * 1)) + total,
          0
        );
      return total;
    },
    avitGlobalSubTotal() {
      let sum = 0;
      if (this.quote.base_spec_version < 3.3) {
        sum += this.quote.avit_toggle ? this.avitTotals("IT") : 0;
        sum += this.quote.avit_toggle ? this.avitTotals("AV") : 0;
        sum += this.quote.avit_toggle ? this.avitDI("IT") : 0;
        sum += this.quote.avit_toggle ? this.avitDI("AV") : 0;
      } else {
        sum += this.quote.it_toggle ? this.avitTotals("IT") : 0;
        sum += this.quote.av_toggle ? this.avitTotals("AV") : 0;
        sum += this.quote.it_toggle ? this.avitDI("IT") : 0;
        sum += this.quote.av_toggle ? this.avitDI("AV") : 0;
      }
      return sum;
    },
    avitGlobalCostSubTotal() {
      let sum = 0;
      if (this.quote.base_spec_version < 3.3) {
        sum += this.quote.avit_toggle ? this.avitCostTotals("IT") : 0;
        sum += this.quote.avit_toggle ? this.avitCostTotals("AV") : 0;
      } else {
        sum += this.quote.av_toggle ? this.avitCostTotals("AV") : 0;
        sum += this.quote.it_toggle ? this.avitCostTotals("IT") : 0;
      }
      return sum;
    },
    // calculate base spec adjustment qty
    constructionAdjustmentTotalQuantity(constructionWorkId) {
      var sum = 0;
      this.quote.options
        .filter((n) => n.construction_work_id == constructionWorkId)
        .forEach((e) => {
          sum += parseFloat(e.quantity);
        });
      return parseFloat(sum);
    },
    contingencyFeeCalc(construction_spec) {
      let result =
        this.quote.base_spec_version > 3.6
          ? Number(
              Number(construction_spec.total_cost) *
                (this.quote.contingency_fee_percentage / 100)
            )
          : 0;
      if (
        this.excluded_codes_contingency_fee.filter(
          (e) => e == construction_spec.uniformat
        ).length > 0
      ) {
        result = 0;
      }     
      // // create custom adjustment for elements that qty is adjusted to 0
      // if (
      // (  construction_spec.qty +
      //     this.constructionAdjustmentTotalQuantity(
      //       construction_spec.construction_work_id
      //     ) )==
      //   0
      // ) {
      //   result = 0;
      // }
      // let cstrn_iten_filter = this.quote.construction_spec_quote.find((c)=>c.construction_work_id==construction_spec.construction_work_id);
      // if (cstrn_iten_filter&&
      //   cstrn_iten_filter.qty +
      //     this.constructionAdjustmentTotalQuantity(
      //       construction_spec.construction_work_id
      //     ) ==
      //   0
      // ) {
      //   result = 0;
      // }
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.child_numbers {
  font-size: 12px;
  font-weight: 500;
}
</style>
